<template>
  <div class="main">
    <div id="user" class="app-container">
      <!-- <div class="leftList">
        <div class="user-center__R8SKINS-logo">
          <div
            class="picture-container user-center__R8SKINS-logo__picture"
            style="padding-top: 13.0169%; width: 100%"
          >
            <div
              class="picture"
              style="
                background-image: url('http://r8skins.com/img/logo.6a88e67b.png');
                background-size: contain;
              "
            ></div>
          </div>
        </div>
        <ul
          class="Menu ivu-menu ivu-menu-light ivu-menu-vertical"
          style="width: auto"
        >
          <li class="ivu-menu-item  ivu-menu-item-selected">
            <a
              href="/#/userinfo"
              class="router-link-exact-active router-link-active"
              aria-current="page"
              ><i
                class="iconfont"
                style="font-size: 19px"
              >&#xe65a;</i
              >个人中心
            </a>
          </li>
          <li class="ivu-menu-item ivu-menu-item-active ivu-menu-item-selected">
            <a
              href="/#/recaption"
              class="router-link-exact-active router-link-active"
              aria-current="page"
              ><i
                class="iconfont"
                style="font-size: 19px"
              >&#xe61d;</i
              >我的库存
            </a>
          </li>
          <li class="ivu-menu-item  ivu-menu-item-selected">
            <a
              href="/#/agency"
              class="router-link-exact-active router-link-active"
              aria-current="page"
              ><i
                class="iconfont"
                style="font-size: 19px"
              >&#xe61a;</i
              >合作中心
            </a>
          </li>
          <li class="ivu-menu-item ">
            <a href="/#/record" class=""
              ><i
                class="iconfont"
                style="font-size: 19px"
              >&#xe61e;</i>
              充值记录
            </a>
          </li>
        </ul>
      </div> -->
      <leftList></leftList>

      <div class="rightView">
        <div class="recaptionBox">
          <div class="recaptionBox_bg">
            <div class="recaptionBox__title">
              <div class="recaptionBox__title__left">
                <div class="recaptionBox__title__left__stock">饰品库存</div>
                <div class="recaptionBox__title__left__test">
                  取回货物不到账联系客服QQ: {{this.$store.state.webdata.qq}}
                </div>
              </div>
              <div class="recaptionBox__title__right">
                <a
                  :href=this.$store.state.webdata.qq_qun_url
                  target="_blank"
                  class="join-us-link"
                  >加入官方群</a
                >
              </div>
            </div>
            <div class="recaptionBox__buttonList">
              <div class="recaptionBox__buttonList__left">
                <label class="recaptionBox__buttonList__left__agreement"
                  ><input
                    type="checkbox"
                    hidden="hidden"
                    class="recaptionBox__buttonList__left__agreement__checkbox"
                    @click="letsGetThisFuckingCheck"
                  />
                  <!-- <span class="recaptionBox__buttonList__left__agreement__checkbox"></span>  这个是禁用的单选框-->
                  <span>全选</span></label
                ><span style="margin-left: 20px">饰品状态：</span>
                <div
                  class="ivu-select ivu-select-single ivu-select-default"
                  style="width: 100px"
                >
                  <div tabindex="0" class="ivu-select-selection">
                    <input type="hidden" value="1" />
                    <div class="">
                      <!---->
                      <!---->
                      <!-- <span class="ivu-select-selected-value">仓库中</span> -->
                      <el-dropdown trigger="click" @command="handleCommand">
                      <span class="el-dropdown-link">
                        {{typedatas}}<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command="0">未发货</el-dropdown-item>
                          <el-dropdown-item command="1">已发货</el-dropdown-item>
                          <el-dropdown-item command="2">不通过</el-dropdown-item>
                          <el-dropdown-item command="3">出售</el-dropdown-item>
                          <el-dropdown-item command="4">正在取回中</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                      <!---->
                      <!---->
                      <i
                        class="ivu-icon ivu-icon-ios-arrow-down ivu-select-arrow"
                      ></i>
                    </div>
                  </div>
                  <div
                    class="ivu-select-dropdown"
                    style="
                      display: none;
                      min-width: 100px;
                      position: absolute;
                      will-change: top, left;
                      transform-origin: center top;
                      top: 355px;
                      left: 601px;
                    "
                    x-placement="bottom-start"
                  >
                    <ul class="ivu-select-not-found" style="display: none">
                      <li>无匹配数据</li>
                    </ul>
                    <ul class="ivu-select-dropdown-list">
                      <li class="ivu-select-item ivu-select-item-selected">
                        仓库中
                      </li>
                      <li class="ivu-select-item">取回中</li>
                      <li class="ivu-select-item">取回记录</li>
                    </ul>
                    <ul class="ivu-select-loading" style="display: none">
                      加载中
                    </ul>
                  </div>
                </div>
              </div>
              <div class="recaptionBox__buttonList__right" >
                <div class="recaptionBox__buttonList__right__choicetotal">
                  <p>已选{{checkbox.length}}件</p>
                  <p>价值：<span>{{widmoney}}</span></p>
                </div>
                <div class="recaptionBox__buttonList__right__retrieve" @click="backWin()" v-show="typedata == '0' || typedata == '2'">
                  取回
                </div>
                <div class="recaptionBox__buttonList__right__sold" @click="toCoin()" v-show="typedata != '3'&& typedata != '1' && typedata != '4'">出售</div>
              </div>
              <p class="recaptionBox__buttonList__right__choicetotals" style="display: none;">
                  已选{{checkbox.length}}件,价值:<span style="color: #ff9939;">{{widmoney}}</span>
                </p>
            </div>
            <div class="title-min"></div>
            <div class="recaptionBox__content">
              <div class="ant-spin-nested-loading" style="width: 100%">
                <div class="ant-spin-container">
                  <ul class="ul backageUl" v-if="checkboxList.length != 0">
                    <li style="background-size:100% 100%" v-for="(item,index) in checkboxList" :key="index" v-if="index < limit" :class="checkbox.includes(index)?'active':''"  @click="check(index,item.w_id,item.money)" :style="{backgroundImage:'url('+item.back_logo+')'}">
                      <div class="recaptionBox__content__header">
                        <div class="left"></div>
                      <div class="right">{{item.money}}</div>
                      </div>
                      <div class="recaptionBox__content__ornaments">
                        <div
                          class="recaptionBox__content__ornaments__rarity-picture n"
                        ></div>
                        <img
                          :src=item.logo
                          alt=""
                          class="recaptionBox__content__ornaments__picture"
                        />
                        <div
                          class="recaptionBox__content__ornaments__awardName"
                        >
                          <p v-if="item.msg != null && item.msg != '' && typedata == '2'" style="color: red">理由:{{item.msg}}</p>
                          <p>{{item.title}}</p>

                        </div>
                      </div>
                    </li>
                    <li class="award award_holder"></li>
                    <li class="award award_holder"></li>
                    <li class="award award_holder"></li>
                  </ul>

                  <ul class="ul backageUl" v-if="checkboxList.length == 0">
                    <li>暂无库存</li>
                    <li class="award award_holder"></li>
                    <li class="award award_holder"></li>
                  </ul>
                </div>
              </div>

              <div class="pageBox" v-if="checkboxList.length != 0">
                <ul class="ivu-page">
                  <li title="1" class="ivu-page-item ivu-page-item-active">
                    <el-pagination
                      small
                      background
                      layout="prev, pager, next"
                      @current-change="pagedata"
                      :total="total"
                      :page-size="limit"
                      >
                    </el-pagination>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 红包 -->
    <Redenvelopes></Redenvelopes>
  </div>
</template>

<script>
import Redenvelopes from "../components/Redenvelopes";
import leftList from "../components/userleftList"
export default {
  components: {
    Redenvelopes,
    leftList,
  },
  data() {
    return {
      checkbox:[],//选择饰品加载边框
      checkboxList:[],//页面数据
      total:0,//多少条数据
      page:1,//分页
      limit:12,//一页多少条
      typedata:'0',//分类查询s
      typedatas:'未发货',//
      wid:[],//选择id
      widmoney:'0',//选择id加起来的money
      isdata:true,//是否有库存
    };
  },
  computed: {
    //判断是否全部选中
    isCheckAll(){
      if(this.checkbox.length==this.checkboxList.length && this.checkbox.length != 0){
        return true;
      }
      return false;
    }
  },
  watch:{
    checkbox(){
      if(this.checkbox.length == this.checkboxList.length && this.checkbox.length != 0){
        document.getElementsByClassName('recaptionBox__buttonList__left__agreement__checkbox')[0].checked = true
      }else{
        document.getElementsByClassName('recaptionBox__buttonList__left__agreement__checkbox')[0].checked = false
      }
    }
  },
  methods: {
    // 红包
    redPrize(){
      document.getElementsByClassName('ivu-modal')[0].style.display="block";
      document.getElementById('isquan').classList.add("backghui")
    },
    //拿到当前页数
    pagedata(key){
      this.clearCheckbox()
      this.page = key
      this.getdatare()
    },
    //多选
    check(i,b,idmoney){
      var idx = this.checkbox.indexOf(i);
      //如果已经选中了，那就取消选中，如果没有，则选中
      if(idx>-1){
        this.checkbox.splice(idx,1);
        this.wid.splice(idx,1);
        this.widmoney = parseFloat(this.widmoney) - parseFloat(idmoney)
        this.widmoney = this.widmoney.toFixed(2)
      }else{
        this.checkbox.push(i);
        this.wid.push(b);
        this.widmoney = parseFloat(this.widmoney) + parseFloat(idmoney)
        this.widmoney = this.widmoney.toFixed(2)

      }
      // console.log(this.wid);
    },
    //反全选 反不选
    letsGetThisFuckingCheck(){
      if(this.isCheckAll){
        this.clearCheckbox();
      }else{
        this.checkAll()
      }
    },
    //选中全部
    checkAll(){
      this.checkbox = [];
      this.wid = [];
      this.widmoney = '0'
      for(var i=0;i<this.checkboxList.length;i++){
        this.checkbox.push(i)
        this.wid.push(this.checkboxList[i].w_id);
        this.widmoney = parseFloat(this.widmoney) + parseFloat(this.checkboxList[i].money)
        this.widmoney = this.widmoney.toFixed(2)
      }
    },
    //清空选择
    clearCheckbox(){
      this.checkbox = [];
      this.wid = [];
      this.widmoney = '0'
    },
    //分类
    handleCommand(command) {
      this.typedata = command
      this.typedatas = command == '0' ? '未发货' : command == '1' ? '已发货' : command == '2' ? '不通过' : command == '3' ? '出售' : '正在取回中'
      this.page = 1
      this.getdatare()
      this.clearCheckbox()
      document.getElementsByClassName('recaptionBox__buttonList__left__agreement__checkbox')[0].checked = false
    },
    //获取页面数据
    getdatare(){
      this.$axios({
        url:'win/get/list',
        method:'post',
        data:{
          page:this.page,
          limit:this.limit,
          type:this.typedata
        }
      }).then( res => {
        //因为有没有数据返回的status不一样
        if(res.data.status == 200){
          this.checkboxList = res.data.data
          this.total = res.data.count
        }else{
          this.checkboxList = []
        }

      })
    },
    //库存出售
    toCoin(){
      if(this.wid.length != 0){
          this.$axios({
          url:'win/winToCoin',
          method:'post',
          data:{
            w_id:this.wid.toString()
          }
          }).then ( res => {
            if(res.data.status == 200){
              this.$message({
                showClose: true,
                message: res.data.msg,
                type: "success",
              });
              //用户余额
              let mone = parseFloat(this.widmoney) + parseFloat(this.$store.state.userdata.coin)
              mone = mone.toFixed(2)
              this.$store.commit("usercoin_up", mone);
              this.clearCheckbox()
              this.getdatare()
            }else{
              this.$message({
                showClose: true,
                message: res.data.msg,
                type: "error",
              });
            }
          })
      }else{
        this.$message({
          showClose: true,
          message: '您还选中要出售的奖品',
          type: "error",
        });
      }

    },
    //库存取回
    backWin(){
      if(this.wid.length != 0){
          this.$axios({
          url:'win/get/backWin',
          method:'post',
          data:{
            w_id:this.wid.toString()
          }
          }).then ( res => {
            if(res.data.status == 200){
              this.$message({
                showClose: true,
                message: res.data.msg,
                type: "success",
              });
              this.clearCheckbox()
              this.getdatare()
            }else{
              this.$message({
                showClose: true,
                message: res.data.msg,
                type: "error",
              });
            }
          })
      }else{
        this.$message({
          showClose: true,
          message: '您还选中要取回的奖品',
          type: "error",
        });
      }

    },
    //登录
    handleZhu() {
      document.getElementsByClassName("form-wnd")[0].classList.add("active");
      document.getElementsByClassName("popup-bg")[0].classList.add("active");
      document.getElementsByClassName("switch-block")[0].classList.add("active");
      document.getElementsByClassName("switch-block")[1].classList.remove("active");
      document.getElementsByClassName("button")[0].classList.add("active");
      document.getElementsByClassName("button")[1].classList.remove("active");
      this.isBar = false;
      this.password=''
    },
  },
  mounted() {
    if(!localStorage.getItem("token")){
      this.$message({
        showClose: true,
        message: '请先登录'
      });
      this.$router.push('/')
      //提示登录框
      setTimeout( ()=> {
        this.handleZhu()
      },300)
    }
    this.getdatare()
  },
};
</script>

<style scoped lang='less'>
@import url("../assets/csscopy/user/share.css");
@import url("../assets/csscopy/user/recaption.css");
.picture{
  background-image: url("../assets/img/logo.6a88e67b.png") !important;
}
.active{
  border: 2px solid red;
}
.el-dropdown-link {
    cursor: pointer;
    color:#fda539;
}
@media screen and (max-width: 800px) {
  .leftList{
    width: 20% !important;
  }
  .rightView{
    display:block !important;
    width: 80%;
  }
  .recaptionBox_bg{
    padding: 20px 10px;
  }
  .recaptionBox__title{
    display: block  !important;
  }
  .recaptionBox__title__left__test{
    padding-left: 0px  !important;
  }
  .ul{
    padding: 0 !important;
    li{
      width: 48%  !important;
    }
  }
  .recaptionBox__buttonList{
    flex-direction: column;
    flex-wrap: wrap;
    .recaptionBox__buttonList__left{
      padding-left: 0px !important;
    }
  }
  .recaptionBox__buttonList__right__choicetotal{
    display: none;
  }
  .recaptionBox__buttonList__right__choicetotals{
    display: block !important;
  }
  .recaptionBox__buttonList__right__retrieve{
    margin: 0 !important;
    margin-right: 10px !important;
  }
}

</style>
